module.exports = {
  Mappings: [
    {
      client: "BEW",
      fieldMappings: {
        spCustomerToHsCompany: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },

            {
              fromField: "saveToSpire",
              toField: "savetospire",
              manadatory: true,
              checked: true,
              defaultValue: "No",
            },
            {
              fromField: "id",
              toField: "customer_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "customer_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "openOrders",
              toField: "customer_openorders",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastInvoiceDate",
              toField: "customer_lastinvoicedate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "averageDaysToPay",
              toField: "customer_averagedaystopay",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentAmt",
              toField: "customer_lastpaymentamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentDate",
              toField: "customer_lastpaymentdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "userDef2",
              toField: "customer_customertype",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "address.sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.email",
              toField: "customer_primarycontactemail",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "ship_to_address_id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "s2h_createdby",
              toField: "s2h_createdby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            {
              fromField: "s2h_modifiedby",
              toField: "s2h_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            //Below this fields are added for Beverage World
            {
              fromField: "hold",
              toField: "customer_onhold",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.defaultWarehouse",
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "creditLimit",
              toField: "customer_credit_limit",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "creditBalance",
              toField: "customer_credit_balance",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "specialCode",
              toField: "customer_special_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.code",
              toField: "customer_payment_terms_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.description",
              toField: "customer_payment_terms_description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "thisYearSales",
              toField: "customer_this_year_sales",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastYearSales",
              toField: "customer_last_year_sales",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "nextYearSales",
              toField: "customer_next_year_sales",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "thisYearGP",
              toField: "customer_this_year_gp",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastYearGP",
              toField: "customer_last_year_gp",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "nextYearGP",
              toField: "customer_next_year_gp",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceType",
              toField: "customer_invoice_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "statementType",
              toField: "customer_statement_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "reference",
              toField: "customer_reference",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "upload",
              toField: "customer_upload",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.deliverydate",
              toField: "address_udf_deliverydate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit1-4",
              toField: "address_udf_solicit_1_4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit2-5",
              toField: "address_udf_solicit_2_5",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit3-6",
              toField: "address_udf_solicit_3_6",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contact",
              toField: "address_udf_contact_method",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contactname",
              toField: "address_udf_contact_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.pallettype",
              toField: "address_udf_pallet_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },

            {
              fromField: "address.udf.sepnosep",
              toField: "address_udf_attention",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.receiving",
              toField: "address_udf_receiving",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spInvoiceToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_invoice_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceNo",
              toField: "spire_invoice_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territoryCode",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "spire_invoice_trans_no",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salespersonNo",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "salespersonName",
              toField: "spire_invoice_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Fields below this are added for BEW
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsText", // Terms Description
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        aged: {
          fields: [
            {
              fromField: "future",
              toField: "ar_aged_future",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "current",
              toField: "ar_aged_current",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period0",
              toField: "ar_aged_period0",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period1",
              toField: "ar_aged_period1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period2",
              toField: "ar_aged_period2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period3",
              toField: "ar_aged_period3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalBalance",
              toField: "ar_aged_totalbalance",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
          ],
        },
        transaction: {
          fields: [
            {
              fromField: "transType",
              toField: "ar_transactions_transtype",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "ar_transactions_transno",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "refNo",
              toField: "ar_transactions_refno",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "debitAmt",
              toField: "ar_transactions_debitamt",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "creditAmt",
              toField: "ar_transactions_creditamt",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "linkBalance",
              toField: "ar_transactions_linkbalance",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "dueDate",
              toField: "ar_transactions_duedate",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "ar_transactions_referenceno",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "ar_transactions_customerpo",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
          ],
        },
        contact: {
          fields: [
            {
              fromField: "name",
              toField: "firstname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastname",
              toField: "lastname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "email",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "associatedcompanyid",
              toField: "associatedcompanyid",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_savetospire",
              toField: "address_savetospire",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "spire_address_type",
              toField: "spire_address_type",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_customerno",
              toField: "address_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_id",
              toField: "address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
          ],
        },
        shiptoaddress: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "linkNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "customer_primarycontactemail",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //fields below this are added for BEW
            {
              fromField: "fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "defaultWarehouse", //Default Warehouse
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.deliverydate",
              toField: "address_udf_deliverydate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit1-4",
              toField: "address_udf_solicit_1_4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit2-5",
              toField: "address_udf_solicit_2_5",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit3-6",
              toField: "address_udf_solicit_3_6",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contact",
              toField: "address_udf_contact_method",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contactname",
              toField: "address_udf_contact_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.pallettype",
              toField: "address_udf_pallet_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },

            {
              fromField: "address.udf.sepnosep",
              toField: "address_udf_attention",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.udf.receiving",
              toField: "address_udf_receiving",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        lineitems: {
          fields: [
            {
              fromField: "inventory.description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "committedQty",
              toField: "quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "unitPrice",
              toField: "price",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_line_item_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products_inventory: {
          fields: [
            {
              fromField: "availableQty",
              toField: "available_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onHandQty",
              toField: "onhand_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "committedQty",
              toField: "committed_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "backorderQty",
              toField: "backorder_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onPurchaseQty",
              toField: "onpurchase_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "productCode",
              toField: "spire_product_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_inventory_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products: {
          fields: [
            {
              fromField: "description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_inventory_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_sporder: {
          fields: [
            {
              fromField: "properties.spire_order_type",
              toField: "type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.createdate",
              toField: "orderDate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_spcurrency: {
          fields: [
            {
              fromField: "properties.deal_currency_code",
              toField: "code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spcustomer_spordercustomer: {
          fields: [
            {
              fromField: "id",
              toField: "id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customerNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_sporderitem: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.quantity",
              toField: "orderQty",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_spinventory: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spOrderToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_order_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "spire_order_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_order_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalOrdered",
              toField: "spire_total_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "spire_subtotal_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "amount",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: false, //false for BEW
              defaultValue: null,
            },
            {
              fromField: "backordered",
              toField: "spire_order_backordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phaseId",
              toField: "spire_order_phase_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsText",
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "hold",
              toField: "spire_order_hold",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "quoteExpires",
              toField: "spire_quote_expires",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "batchNo",
              toField: "spire_batchno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "grossProfit2",
              toField: "spire_gross_profit2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard2", //Total Cost Ordered (Standard)
              toField: "spire_total_cost_standard2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent2", //Total Cost Ordered (Current)
              toField: "spire_total_cost_current2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage2", //Total Cost Ordered (Inventory)
              toField: "spire_total_cost_average2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "amountPaid", //Amount Paid
              toField: "spire_amount_paid",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "amountUnpaid", //Amount Remaining
              toField: "spire_amount_unpaid",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "percentPaid", //Percent Paid
              toField: "spire_percent_paid",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "udf.ordertype", //UDF order type added as per Acarts request
              toField: "spire_udf_ordertype",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
      },
    },
    {
      client: "ALL",
      fieldMappings: {
        spCustomerToHsCompany: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },

            {
              fromField: "saveToSpire",
              toField: "savetospire",
              manadatory: true,
              checked: true,
              defaultValue: "No",
            },
            {
              fromField: "id",
              toField: "customer_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "customer_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "openOrders",
              toField: "customer_openorders",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastInvoiceDate",
              toField: "customer_lastinvoicedate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "averageDaysToPay",
              toField: "customer_averagedaystopay",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentAmt",
              toField: "customer_lastpaymentamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentDate",
              toField: "customer_lastpaymentdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "userDef2",
              toField: "customer_customertype",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.email",
              toField: "customer_primarycontactemail",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "ship_to_address_id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "s2h_createdby",
              toField: "s2h_createdby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            {
              fromField: "s2h_modifiedby",
              toField: "s2h_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            //Below this fields are added for Beverage World
            {
              fromField: "hold",
              toField: "customer_onhold",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.defaultWarehouse",
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "creditLimit",
              toField: "customer_credit_limit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "creditBalance",
              toField: "customer_credit_balance",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "specialCode",
              toField: "customer_special_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.code",
              toField: "customer_payment_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.description",
              toField: "customer_payment_terms_description",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "thisYearSales",
              toField: "customer_this_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "lastYearSales",
              toField: "customer_last_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "nextYearSales",
              toField: "customer_next_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "thisYearGP",
              toField: "customer_this_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "lastYearGP",
              toField: "customer_last_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "nextYearGP",
              toField: "customer_next_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "invoiceType",
              toField: "customer_invoice_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "statementType",
              toField: "customer_statement_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "reference",
              toField: "customer_reference",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "upload",
              toField: "customer_upload",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.deliverydate",
              toField: "address_udf_deliverydate",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit1-4",
              toField: "address_udf_solicit_1_4",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit2-5",
              toField: "address_udf_solicit_2_5",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit3-6",
              toField: "address_udf_solicit_3_6",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contact",
              toField: "address_udf_contact_method",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contactname",
              toField: "address_udf_contact_name",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.pallettype",
              toField: "address_udf_pallet_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },

            {
              fromField: "address.udf.sepnosep",
              toField: "address_udf_attention",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.receiving",
              toField: "address_udf_receiving",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "currency",
              toField: "customer_currency",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        spInvoiceToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_invoice_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceNo",
              toField: "spire_invoice_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territoryCode",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "spire_invoice_trans_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salespersonNo",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salespersonName",
              toField: "spire_invoice_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below this fields are added for BEW
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsText", // Terms Description
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        aged: {
          fields: [
            {
              fromField: "future",
              toField: "ar_aged_future",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "current",
              toField: "ar_aged_current",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period0",
              toField: "ar_aged_period0",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period1",
              toField: "ar_aged_period1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period2",
              toField: "ar_aged_period2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period3",
              toField: "ar_aged_period3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalBalance",
              toField: "ar_aged_totalbalance",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        transaction: {
          fields: [
            {
              fromField: "transType",
              toField: "ar_transactions_transtype",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "ar_transactions_transno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "refNo",
              toField: "ar_transactions_refno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "debitAmt",
              toField: "ar_transactions_debitamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "creditAmt",
              toField: "ar_transactions_creditamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "linkBalance",
              toField: "ar_transactions_linkbalance",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "dueDate",
              toField: "ar_transactions_duedate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "ar_transactions_referenceno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "ar_transactions_customerpo",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        contact: {
          fields: [
            {
              fromField: "name",
              toField: "firstname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastname",
              toField: "lastname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "email",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "associatedcompanyid",
              toField: "associatedcompanyid",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_savetospire",
              toField: "address_savetospire",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "spire_address_type",
              toField: "spire_address_type",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_customerno",
              toField: "address_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_id",
              toField: "address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
          ],
        },
        shiptoaddress: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "linkNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "customer_primarycontactemail",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below this fields are added for BEW
            {
              fromField: "fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "defaultWarehouse", //Default Warehouse
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.deliverydate",
              toField: "address_udf_deliverydate",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit1-4",
              toField: "address_udf_solicit_1_4",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit2-5",
              toField: "address_udf_solicit_2_5",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit3-6",
              toField: "address_udf_solicit_3_6",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contact",
              toField: "address_udf_contact_method",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contactname",
              toField: "address_udf_contact_name",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.pallettype",
              toField: "address_udf_pallet_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },

            {
              fromField: "address.udf.sepnosep",
              toField: "address_udf_attention",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.receiving",
              toField: "address_udf_receiving",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        lineitems: {
          fields: [
            {
              fromField: "inventory.description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderQty",
              toField: "quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "unitPrice",
              toField: "price",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_line_item_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products_inventory: {
          fields: [
            {
              fromField: "availableQty",
              toField: "available_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onHandQty",
              toField: "onhand_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "committedQty",
              toField: "committed_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "backorderQty",
              toField: "backorder_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onPurchaseQty",
              toField: "onpurchase_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "productCode",
              toField: "spire_product_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_inventory_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products: {
          fields: [
            {
              fromField: "description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_inventory_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_sporder: {
          fields: [
            {
              fromField: "properties.spire_order_type",
              toField: "type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.createdate",
              toField: "orderDate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_spcurrency: {
          fields: [
            {
              fromField: "properties.deal_currency_code",
              toField: "code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spcustomer_spordercustomer: {
          fields: [
            {
              fromField: "id",
              toField: "id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customerNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_sporderitem: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.quantity",
              toField: "orderQty",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_spinventory: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spOrderToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_order_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "spire_order_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_order_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalOrdered",
              toField: "spire_total_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "spire_subtotal_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "amount",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "backordered",
              toField: "spire_order_backordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phaseId",
              toField: "spire_order_phase_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsText",
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below fields are added for BEW
            {
              fromField: "hold",
              toField: "spire_order_hold",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "quoteExpires",
              toField: "spire_quote_expires",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "batchNo",
              toField: "spire_batchno",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfit2",
              toField: "spire_gross_profit2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard2", //Total Cost Ordered (Standard)
              toField: "spire_total_cost_standard2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent2", //Total Cost Ordered (Current)
              toField: "spire_total_cost_current2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage2", //Total Cost Ordered (Inventory)
              toField: "spire_total_cost_average2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "amountPaid", //Amount Paid
              toField: "spire_amount_paid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "amountUnpaid", //Amount Remaining
              toField: "spire_amount_unpaid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "percentPaid", //Percent Paid
              toField: "spire_percent_paid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        spOrderWithoutIdToHsDeal: {
          fields: [
            {
              fromField: "phaseId",
              toField: "spire_phase_id",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
      },
    },
    {
      client: "WWP",
      fieldMappings: {
        spCustomerToHsCompany: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },

            {
              fromField: "saveToSpire",
              toField: "savetospire",
              manadatory: true,
              checked: true,
              defaultValue: "No",
            },
            {
              fromField: "id",
              toField: "customer_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "customer_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "openOrders",
              toField: "customer_openorders",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastInvoiceDate",
              toField: "customer_lastinvoicedate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "averageDaysToPay",
              toField: "customer_averagedaystopay",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentAmt",
              toField: "customer_lastpaymentamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastPaymentDate",
              toField: "customer_lastpaymentdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "userDef2",
              toField: "customer_customertype",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.email",
              toField: "customer_primarycontactemail",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "ship_to_address_id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "s2h_createdby",
              toField: "s2h_createdby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            {
              fromField: "s2h_modifiedby",
              toField: "s2h_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: "SpireAPI",
            },
            //Below this fields are added for Beverage World
            {
              fromField: "hold",
              toField: "customer_onhold",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.defaultWarehouse",
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "creditLimit",
              toField: "customer_credit_limit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "creditBalance",
              toField: "customer_credit_balance",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "specialCode",
              toField: "customer_special_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.code",
              toField: "customer_payment_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "paymentTerms.description",
              toField: "customer_payment_terms_description",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "thisYearSales",
              toField: "customer_this_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "lastYearSales",
              toField: "customer_last_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "nextYearSales",
              toField: "customer_next_year_sales",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "thisYearGP",
              toField: "customer_this_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "lastYearGP",
              toField: "customer_last_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "nextYearGP",
              toField: "customer_next_year_gp",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "invoiceType",
              toField: "customer_invoice_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "statementType",
              toField: "customer_statement_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "reference",
              toField: "customer_reference",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "upload",
              toField: "customer_upload",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },

            {
              fromField: "currency",
              toField: "customer_currency",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            //New UDF fields for Whites Wear
            {
              fromField: "address.udf.snow",
              toField: "snow",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.highway",
              toField: "highway",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.drilling",
              toField: "drilling",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.forestry",
              toField: "forestry",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.smallconst",
              toField: "smallconst",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.agriculture",
              toField: "agriculture",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.equiprepair",
              toField: "equiprepair",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.landscaping",
              toField: "landscaping",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.dealerrental",
              toField: "dealerrental",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.largconstruction",
              toField: "largeconstruction",
              manadatory: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.municipalboolean",
              toField: "municipalboolean",
              manadatory: false,
              defaultValue: null,
            },
          ],
        },
        spInvoiceToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_invoice_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "invoiceNo",
              toField: "spire_invoice_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territoryCode",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "spire_invoice_trans_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salespersonNo",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salespersonName",
              toField: "spire_invoice_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below this fields are added for BEW
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsText", // Terms Description
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        aged: {
          fields: [
            {
              fromField: "future",
              toField: "ar_aged_future",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "current",
              toField: "ar_aged_current",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period0",
              toField: "ar_aged_period0",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period1",
              toField: "ar_aged_period1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period2",
              toField: "ar_aged_period2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "period3",
              toField: "ar_aged_period3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalBalance",
              toField: "ar_aged_totalbalance",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        transaction: {
          fields: [
            {
              fromField: "transType",
              toField: "ar_transactions_transtype",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "transNo",
              toField: "ar_transactions_transno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "refNo",
              toField: "ar_transactions_refno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "debitAmt",
              toField: "ar_transactions_debitamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "creditAmt",
              toField: "ar_transactions_creditamt",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "linkBalance",
              toField: "ar_transactions_linkbalance",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "dueDate",
              toField: "ar_transactions_duedate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "ar_transactions_referenceno",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "ar_transactions_customerpo",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        contact: {
          fields: [
            {
              fromField: "name",
              toField: "firstname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "lastname",
              toField: "lastname",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "email",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "associatedcompanyid",
              toField: "associatedcompanyid",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_savetospire",
              toField: "address_savetospire",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "spire_address_type",
              toField: "spire_address_type",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_customerno",
              toField: "address_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
            {
              fromField: "address_id",
              toField: "address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
              hidden: true,
            },
          ],
        },
        shiptoaddress: {
          fields: [
            {
              fromField: "name",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "ship_to_address_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "linkNo",
              toField: "customer_customerno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "email",
              toField: "customer_primarycontactemail",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "city",
              toField: "city",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line1",
              toField: "address",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line2",
              toField: "address2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line3",
              toField: "customer_address_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "line4",
              toField: "customer_address_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "postalCode",
              toField: "zip",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "provState",
              toField: "state",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "country",
              toField: "country",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phone.number",
              toField: "phone",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.code",
              toField: "customer_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "territory.description",
              toField: "customer_territory_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.code",
              toField: "customer_salesperson_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "salesperson.name",
              toField: "cusotmer_salesperson_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below this fields are added for BEW
            {
              fromField: "fax.number",
              toField: "customer_fax_number",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "defaultWarehouse", //Default Warehouse
              toField: "customer_default_warehouse",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "sellLevel",
              toField: "customer_pricelevel",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.deliverydate",
              toField: "address_udf_deliverydate",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit1-4",
              toField: "address_udf_solicit_1_4",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit2-5",
              toField: "address_udf_solicit_2_5",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.solicit3-6",
              toField: "address_udf_solicit_3_6",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contact",
              toField: "address_udf_contact_method",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.contactname",
              toField: "address_udf_contact_name",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.pallettype",
              toField: "address_udf_pallet_type",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },

            {
              fromField: "address.udf.sepnosep",
              toField: "address_udf_attention",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "address.udf.receiving",
              toField: "address_udf_receiving",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
        lineitems: {
          fields: [
            {
              fromField: "inventory.description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderQty",
              toField: "quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "unitPrice",
              toField: "price",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_line_item_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products_inventory: {
          fields: [
            {
              fromField: "availableQty",
              toField: "available_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onHandQty",
              toField: "onhand_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "committedQty",
              toField: "committed_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "backorderQty",
              toField: "backorder_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "onPurchaseQty",
              toField: "onpurchase_quantity",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "productCode",
              toField: "spire_product_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_inventory_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        products: {
          fields: [
            {
              fromField: "description",
              toField: "name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "partNo",
              toField: "hs_sku",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "id",
              toField: "spire_inventory_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_sporder: {
          fields: [
            {
              fromField: "properties.spire_order_type",
              toField: "type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.createdate",
              toField: "orderDate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hsdeal_spcurrency: {
          fields: [
            {
              fromField: "properties.deal_currency_code",
              toField: "code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spcustomer_spordercustomer: {
          fields: [
            {
              fromField: "id",
              toField: "id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerNo",
              toField: "customerNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_sporderitem: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.quantity",
              toField: "orderQty",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        hslineitem_spinventory: {
          fields: [
            {
              fromField: "properties.hs_sku",
              toField: "partNo",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "properties.description",
              toField: "description",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
          ],
        },
        spOrderToHsDeal: {
          fields: [
            {
              fromField: "id",
              toField: "spire_order_id",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderNo",
              toField: "spire_orderno",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.customerNo",
              toField: "spire_cust_no",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customer.name",
              toField: "spire_cust_name",
              manadatory: true,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "status",
              toField: "spire_order_status",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "type",
              toField: "spire_order_type",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "totalOrdered",
              toField: "spire_total_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "spire_subtotal_ordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "orderDate",
              toField: "spire_orderdate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "requiredDate",
              toField: "spire_req_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "total",
              toField: "spire_total",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotalOrdered",
              toField: "amount",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "baseTotal",
              toField: "spire_basetotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "subtotal",
              toField: "spire_subtotal",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "customerPO",
              toField: "spire_customer_po",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "backordered",
              toField: "spire_order_backordered",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.name",
              toField: "spire_shipto_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "fob",
              toField: "spire_fob",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.salesperson.code",
              toField: "spire_salesperson_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "phaseId",
              toField: "spire_order_phase_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.territory.code",
              toField: "spire_territory_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipDescription",
              toField: "spire_ship_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "termsText",
              toField: "spire_order_terms_desc",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "referenceNo",
              toField: "spire_reference_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingCarrier",
              toField: "spire_carrier_name",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shipDate",
              toField: "spire_ship_date",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "trackingNo",
              toField: "spire_tracking_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipId",
              toField: "spire_shipto_id",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line1",
              toField: "spire_ship_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line2",
              toField: "spire_ship_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line3",
              toField: "spire_ship_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.line4",
              toField: "spire_ship_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.city",
              toField: "spire_ship_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.provState",
              toField: "spire_ship_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.postalCode",
              toField: "spire_ship_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.country",
              toField: "spire_ship_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "shippingAddress.shipCode",
              toField: "spire_ship_code",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "created",
              toField: "spire_created",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "createdBy",
              toField: "spire_createdby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modified",
              toField: "spire_modified",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "modifiedBy",
              toField: "spire_modifiedby",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line1",
              toField: "spire_add_line1",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line2",
              toField: "spire_add_line2",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line3",
              toField: "spire_add_line3",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.line4",
              toField: "spire_add_line4",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.city",
              toField: "spire_add_city",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.provState",
              toField: "spire_add_provstate",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.postalCode",
              toField: "spire_add_postalcode",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "address.country",
              toField: "spire_add_country",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "weight",
              toField: "spire_weight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "freight",
              toField: "spire_freight",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            {
              fromField: "wasQuoteNo",
              toField: "spire_org_quote_no",
              manadatory: false,
              checked: true,
              defaultValue: null,
            },
            //Below fields are added for BEW
            {
              fromField: "hold",
              toField: "spire_order_hold",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "invoiceDate",
              toField: "spire_invoice_date",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "quoteExpires",
              toField: "spire_quote_expires",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "batchNo",
              toField: "spire_batchno",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfit",
              toField: "spire_gross_profit",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfitMargin",
              toField: "spire_gross_profit_margin",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "grossProfit2",
              toField: "spire_gross_profit2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard", //Total Cost (Standard)
              toField: "spire_total_cost_standard",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostStandard2", //Total Cost Ordered (Standard)
              toField: "spire_total_cost_standard2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent", //Total Cost (Current)
              toField: "spire_total_cost_current",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostCurrent2", //Total Cost Ordered (Current)
              toField: "spire_total_cost_current2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage", //Total Cost (Inventory)
              toField: "spire_total_cost_average",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "totalCostAverage2", //Total Cost Ordered (Inventory)
              toField: "spire_total_cost_average2",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "incoterms", //Incoterms
              toField: "spire_incoterms",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "termsCode", //Terms Code
              toField: "spire_terms_code",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobNo", //Job No
              toField: "spire_job_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "jobAccountNo", //Job Account
              toField: "spire_job_account_no",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "amountPaid", //Amount Paid
              toField: "spire_amount_paid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "amountUnpaid", //Amount Remaining
              toField: "spire_amount_unpaid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "percentPaid", //Percent Paid
              toField: "spire_percent_paid",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
            {
              fromField: "ediAssociation.status", //EDI Status
              toField: "spire_edi_status",
              manadatory: false,
              checked: false,
              defaultValue: null,
            },
          ],
        },
      },
    },
  ],
};
