import React, { useState } from "react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import GlobalStore from "../../stores/GlobalStore";
import { Mappings } from "../../utils/Mappings";
import AuthStore from "../../stores/AuthStore";
import CompanyStore from "../Create-Admin/CompanyStore";
import Select from "react-select";

function Mapping() {
  const [mappingFields, setMappingFields] = useState();
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [hiddenSelect, setHiddenSelect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  // console.log(`Inside Mapping.js`)
  useEffect(() => {
    ConfigurationStore.getConnections();
    GlobalStore.pageName = "mappings";

    if (localStorage.getItem("userRole") === "super") {
      setHidden(false);
    } else {
      setHiddenSelect(true);
      //0
    }

    let companyName = localStorage.getItem("userCompany");
    if (localStorage.getItem("userRole") !== "super") {
      AuthStore.getClientById(companyName).then((response) => {
        if (response && response.length > 0 && response[0].fieldMappings) {
          // for (var i = 0; i < response[0].fieldMappings.spCustomerToHsCompany.fields.length; i++) {
          //     // response[0].fieldMappings.spCustomerToHsCompany.fields[i].checked = true
          // }
          setMappingFields(response[0].fieldMappings);
        } else {
          /*
                    for (let key of Object.keys(mappingFields)) {

                        for (var i = 0; i < mappingFields[key].fields.length; i++) {
                            mappingFields[key].fields[i].checked = true
                            console.log(`checked set to: ${mappingFields[key].fields[i].checked}`)
                        }
                        console.log(`setting mappingFields`)
                        setMappingFields(mappingFields)
                    }*/
          let clienFieldMappings = Mappings.filter(
            (m) => m.client === companyName
          );
          if (
            clienFieldMappings &&
            Array.isArray(clienFieldMappings) &&
            clienFieldMappings.length > 0
          ) {
            setMappingFields(clienFieldMappings[0].fieldMappings);
          } else {
            clienFieldMappings = Mappings.filter((m) => m.client === "ALL");
            if (
              clienFieldMappings &&
              Array.isArray(clienFieldMappings) &&
              clienFieldMappings.length > 0
            ) {
              setMappingFields(clienFieldMappings[0].fieldMappings);
            }
          }
        }
        // console.log(`setting showform`)
        setShowForm(true);
      });
    } else {
      /*
            for (let key of Object.keys(mappingFields)) {

                for (var i = 0; i < mappingFields[key].fields.length; i++) {
                    mappingFields[key].fields[i].checked = true
                }
                setMappingFields(mappingFields)
            };*/
      AuthStore.getAllClients().then((response) => {
        setSelectableOptions(
          response.map(({ _id: id, name: label, code: value }) => ({
            id,
            label,
            value,
          }))
        );
      });
    }
  }, []);

  const handleChange = (e) => {
    localStorage.setItem("selectedCompany", e.value);
    setDisableButton(false);
    document.getElementById("mapping-btn-sumit-company").style.backgroundColor =
      "#6747CE";
  };

  const handleCheckboxChange = (event) => {
    var mappingName = event.target.id.split("-")[0];
    var mappingIndex = event.target.id.split("-")[1];
    mappingFields[mappingName].fields[mappingIndex].checked =
      !mappingFields[mappingName].fields[mappingIndex].checked;
    setMappingFields(mappingFields);
  };

  const showDataInTable = (key) => {
    return mappingFields[key].fields.map((info, index) => {
      if (info.manadatory === true) {
        info.checked = true;
      }
      return (
        <tr key={"row-" + key + "-" + index}>
          <td>{index + 1}</td>
          <td>{info.fromField}</td>
          <td>{info.toField}</td>
          <td>{String(info.manadatory)}</td>
          <td>{String(info.defaultValue)}</td>
          <td>
            <input
              key={key + "-" + index}
              id={key + "-" + index}
              type="checkbox"
              disabled={info.manadatory}
              defaultChecked={info.checked}
              onClick={handleCheckboxChange}
            />
          </td>
        </tr>
      );
    });
  };

  const handleSubmit = () => {
    let companyCode;
    if (localStorage.getItem("userCompany") === "super") {
      companyCode = localStorage.getItem("selectedCompany");
    } else {
      companyCode = localStorage.getItem("userCompany");
    }
    CompanyStore.updateMapping(companyCode, mappingFields).then((response) => {
      if (response === "Success") {
        alert("Updated Successfully");
        getCustomerData();
      } else {
        alert("Failed to update");
        window.location.reload();
      }
    });
  };

  const getCustomerData = () => {
    setHidden(!hidden);
    setDisableButton(!disableButton);
    document.getElementById("mapping-btn-sumit-company").style.backgroundColor =
      "#d1d1d1";
    const selectedCompany = localStorage.getItem("selectedCompany");
    AuthStore.getClientById(selectedCompany).then((response) => {
      if (response[0] && response[0].fieldMappings) {
        // for (var i = 0; i < response[0].fieldMappings.spCustomerToHsCompany.fields.length; i++) {
        //     // response[0].fieldMappings.spCustomerToHsCompany.fields[i].checked = true
        // }

        setMappingFields(response[0].fieldMappings);
      } else {
        let clienFieldMappings = Mappings.filter(
          (m) => m.client === selectedCompany
        );
        if (
          clienFieldMappings &&
          Array.isArray(clienFieldMappings) &&
          clienFieldMappings.length > 0
        ) {
          setMappingFields(clienFieldMappings[0].fieldMappings);
        } else {
          clienFieldMappings = Mappings.filter((m) => m.client === "ALL");
          if (
            clienFieldMappings &&
            Array.isArray(clienFieldMappings) &&
            clienFieldMappings.length > 0
          ) {
            setMappingFields(clienFieldMappings[0].fieldMappings);
          }
        }
      }
      setShowForm(true);
      setHidden(true);
    });
  };

  return (
    <React.Fragment>
      <div style={{ display: hiddenSelect ? "none" : "grid" }}>
        <div className="input-box">
          <label>Select company</label>
          <Select
            name="companyName"
            placeholder="Select Company"
            options={selectableOptions}
            onChange={handleChange}
          />
          <button
            className="mapping-btn-sumit-company"
            id="mapping-btn-sumit-company"
            disabled={disableButton}
            onClick={(e) => {
              e.preventDefault();
              getCustomerData();
            }}
          >
            submit
          </button>
        </div>
      </div>
      {showForm && (
        <div style={{ display: hidden ? "block" : "none" }}>
          <div className="data-table-1 table-info">
            <label className="data-table-label-name">
              1. sp Customer To Hs Company
            </label>
            <table id="spCustomerToHsCompany">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>

              <tbody id="table-data-spCustomerToHsCompany">
                {showDataInTable("spCustomerToHsCompany")}
              </tbody>
            </table>
          </div>

          <div className="data-table-2 table-info">
            <label className="data-table-label-name">
              2. sp Invoice To Hs Deal
            </label>
            <table id="spInvoiceToHsDeal">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-spInvoiceToHsDeal">
                {showDataInTable("spInvoiceToHsDeal")}
              </tbody>
            </table>
          </div>

          <div className="data-table-3 table-info">
            <label className="data-table-label-name">3. aged</label>
            <table id="aged">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-aged">{showDataInTable("aged")}</tbody>
            </table>
          </div>

          <div className="data-table-4 table-info">
            <label className="data-table-label-name">4. transaction</label>
            <table id="transaction">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-transaction">
                {showDataInTable("transaction")}
              </tbody>
            </table>
          </div>

          <div className="data-table-5 table-info">
            <label className="data-table-label-name">5. contact</label>
            <table id="main-table-contact">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-contact">
                {showDataInTable("contact")}
              </tbody>
            </table>
          </div>

          <div className="data-table-6 table-info">
            <label className="data-table-label-name">6. shiptoaddress</label>
            <table id="main-table-shiptoaddress">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-shiptoaddress">
                {showDataInTable("shiptoaddress")}
              </tbody>
            </table>
          </div>

          <div className="data-table-7 table-info">
            <label className="data-table-label-name">7. lineitems</label>
            <table id="main-table-lineitems">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-lineitems">
                {showDataInTable("lineitems")}
              </tbody>
            </table>
          </div>

          <div className="data-table-8 table-info">
            <label className="data-table-label-name">
              8. products_inventory
            </label>
            <table id="main-table-products_inventory">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-products_inventory">
                {showDataInTable("products_inventory")}
              </tbody>
            </table>
          </div>

          <div className="data-table-9 table-info">
            <label className="data-table-label-name">9. products</label>
            <table id="main-table-products">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-products">
                {showDataInTable("products")}
              </tbody>
            </table>
          </div>

          <div className="data-table-10 table-info">
            <label className="data-table-label-name">10. hsdeal_sporder</label>
            <table id="main-table-hsdeal_sporder">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-hsdeal_sporder">
                {showDataInTable("hsdeal_sporder")}
              </tbody>
            </table>
          </div>
          <div className="data-table-11 table-info">
            <label className="data-table-label-name">
              11. hsdeal_spcurrency
            </label>
            <table id="main-table-hsdeal_spcurrency">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-hsdeal_spcurrency">
                {showDataInTable("hsdeal_spcurrency")}
              </tbody>
            </table>
          </div>

          <div className="data-table-12 table-info">
            <label className="data-table-label-name">
              12. spcustomer_spordercustomer
            </label>
            <table id="main-table-spcustomer_spordercustomer">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-spcustomer_spordercustomer">
                {showDataInTable("spcustomer_spordercustomer")}
              </tbody>
            </table>
          </div>

          <div className="data-table-13 table-info">
            <label className="data-table-label-name">
              13. hslineitem_sporderitem
            </label>
            <table id="main-table-hslineitem_sporderitem">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-hslineitem_sporderitem">
                {showDataInTable("hslineitem_sporderitem")}
              </tbody>
            </table>
          </div>

          <div className="data-table-14 table-info">
            <label className="data-table-label-name">
              14. hslineitem_spinventory
            </label>
            <table id="main-table-hslineitem_spinventory">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-hslineitem_spinventory">
                {showDataInTable("hslineitem_spinventory")}
              </tbody>
            </table>
          </div>

          <div className="data-table-15 table-info">
            <label className="data-table-label-name">15. spOrderToHsDeal</label>
            <table id="main-table-spOrderToHsDeal">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-spOrderToHsDeal">
                {showDataInTable("spOrderToHsDeal")}
              </tbody>
            </table>
          </div>

          <div className="data-table-16 table-info">
            <label className="data-table-label-name">
              16. spOrderWithoutIdToHsDeal
            </label>
            <table id="main-table-spOrderWithoutIdToHsDeal">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>From Field</th>
                  <th>To Field</th>
                  <th>Mandatory</th>
                  <th>Default Field</th>
                  <th>Checked</th>
                </tr>
              </thead>
              <tbody id="table-data-spOrderWithoutIdToHsDeal">
                {showDataInTable("spOrderWithoutIdToHsDeal")}
              </tbody>
            </table>
          </div>

          <div>
            <button
              className="btn-submit-table"
              value={"submit"}
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
          <div className="blank-div"></div>
        </div>
      )}
    </React.Fragment>
  );
}
export default observer(Mapping);
